@import "../../base";
.orderinfo-card-v3 {
  background-color: $foreground-color;
  margin-top: 1.5vw;
  border-radius: $big-radius;
  width: 100%;
  max-width: 950px;
  min-height: 90px;
  height: 30%;
  padding: 10px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: $normal-shadow;
  color: $primary-color;



  &__row {
    display: flex;
    width: 100%;
    height: 50%;
    padding: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__order {
    font-size: 1.3em;
    font-weight: 700;
  }

  &__customer {
    font-size: 1.3em;
  }
  &__quantity {
    font-weight: 700;
  }
}

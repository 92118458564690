@import "../../base";

.product-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #6565881a;

  &:last-child {
    border-bottom: none;
  }

  &__items {
    display: flex;
    width: 55%;
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    width: 55%;
    padding-left: 5px;

    &--first {
      width: 100%;
    }
  }
  &__buttons {
    display: flex;
    width: 45%;
    justify-content: flex-end;
  }
}

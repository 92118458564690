@import "../../base";

.middle-panel {
  width: 30vw;
  max-width: 490px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line-order-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100vh - 200px);
}

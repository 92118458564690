@import "../../base";

.orders-list {
  width: calc(100vw - 390px);
  padding: 10px;
  overflow: auto;
  max-height: calc(100vh - 290px);
  background-color: $foreground-color;
  margin: 20px;
  border-radius: $small-radius;
  box-shadow: $normal-shadow;

  &__container {
    height: 100%;
  }

  &__label {
    font-weight: 600;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  &__header {
    border-bottom: 1px solid rgb(241, 241, 241);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    padding-bottom: 15px;
  }

  &__filter {
    border: 1px solid $primary-color;
    max-width: 390px;
    width: 99%;
    min-width: 50px;
    margin-bottom: 15px;
    margin-right: 10px;
    height: 39px;
    border-radius: $big-radius;
    padding: 10px 20px 10px 25px;

    &:focus {
      border: 1px solid $accent-color;
    }

    &__wrapper {
      padding-right: 10px;
      @include placeholder {
        font-style: italic;
        color: rgb(187, 185, 185);
      }
    }
  }

  &__row {
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(241, 241, 241);

    &:hover {
      background-color: rgb(249, 249, 249);
    }

    &__item {
      padding-left: 20px;
    }
  }

  &__page {
    max-height: $page-height;
    width: calc(100vw - 350px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 60px;
    margin-right: 60px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      margin: 20px;
      width: calc(100vw - 390px);
    }
  }

  // &__filter {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: center;
  //   width: 100%;
  //   @include placeholder {
  //     font-style: italic;
  //     color: rgb(187, 185, 185);
  //   }

  //   &__label {
  //     font-weight: 600;
  //     padding-left: 10px;
  //   }

  //   &__input {
  //     max-width: 400px;
  //     width: 90%;
  //     min-width: 100px;
  //     margin: 35px 20px;
  //     height: 39px;
  //     background-color: $foreground-color;
  //     border-radius: $big-radius;
  //     border: 0;
  //     box-shadow: $normal-shadow;
  //     font-family: $primary-font;
  //     caret-color: $accent-color;
  //     color: $accent-color;
  //     fill: $accent-color;
  //     padding: 10px 20px 10px 25px;

  //     &:disabled {
  //       background-color: $disabled-color;
  //       box-shadow: $disabled-shadow;
  //       fill: $disabled-color;
  //     }

  //     &:focus {
  //       border: 1px solid $accent-color;
  //     }
  //   }
  // }
}

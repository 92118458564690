@import "../base";

.link-block {
  color: $foreground-color;
  background-color: $primary-color;
  box-shadow: $primary-btn-shadow;
  font-family: $primary-font;
  font-weight: 700;
  padding: 15px 20px;
  margin: 40px 30px 20px 30px;
  border: 0;
  height: 100px;
  font-size: 1em;
  min-width: 160px;
  width: calc((100vw - 350px) / 3);
  max-width: 700px;
  letter-spacing: 1px;
  display: flex;
  transition: 0.3s;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: $small-radius;

  &:hover {
    background-color: $accent-color;
    box-shadow: $accent-btn-shadow-hover;
  }

  &:disabled {
    background-color: $disabled-color;
    box-shadow: $disabled-shadow;
  }

  &--delete {
    background-color: $danger-color;
    box-shadow: $danger-btn-shadow;
    align-self: center;

    &:hover {
      box-shadow: $danger-btn-shadow-hover;
      &:disabled {
        background-color: $disabled-color;
        box-shadow: $disabled-shadow;
      }
    }
  }

  &--alt-delete {
    background-color: white;
    box-shadow: none;
    align-self: center;
    padding: 0;
    transition: 0.9s;
    width: 46px;
    min-width: 46px;

    &:hover {
      box-shadow: $danger-btn-alt-shadow;
      &:disabled {
        background-color: $disabled-color;
        box-shadow: $disabled-shadow;
      }
    }
  }

  &--accent {
    font-size: 1.05em;
    height: 52px;
    margin: 5px;
    max-width: 332px;
    width: calc((100vw - 350px) / 4);

    &:hover {
      box-shadow: $accent-btn-shadow-hover;
    }
  }

  &--finish {
    background-color: $primary-color;
    box-shadow: $primary-btn-shadow;

    &:hover {
      box-shadow: $primary-btn-shadow-hover;
      &:disabled {
        background-color: $disabled-color;
        box-shadow: $disabled-shadow;
      }
    }
  }

  &--cancel {
    color: $primary-color;
    background-color: $background-color;
    box-shadow: $cancel-btn-shadow;
    font-weight: 500;
    height: 44px;

    &:hover {
      box-shadow: $primary-btn-shadow-hover;
    }
  }

  &--thin {
    margin: 8px 4px;
    stroke: white;
  }

  &-list {
    width: calc((100vw - 120px));
    max-height: calc((100vh - 337px));
    padding: 10px;
    overflow: auto;
    background-color: $foreground-color;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: $small-radius;
    box-shadow: $normal-shadow;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    &__header {
      display: flex;
      font-size: 1.1em;
      justify-content: flex-start;
      font-weight: 600;
      margin-top: 60px;
      margin-bottom: 13px;
      padding-left: 10px;
    }

    &__filter {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      &__label {
        font-weight: 600;
        padding-left: 10px;
      }

      &__input {
        max-width: 400px;
        width: 90%;
        min-width: 100px;
        margin: 35px 20px;
        height: 39px;
        background-color: $foreground-color;
        border-radius: $big-radius;
        border: 0;
        box-shadow: $normal-shadow;
        font-family: $primary-font;
        caret-color: $accent-color;
        color: $accent-color;
        fill: $accent-color;
        padding: 10px 20px 10px 40px;

        &:disabled {
          background-color: $disabled-color;
          box-shadow: $disabled-shadow;
          fill: $disabled-color;
        }

        &:focus {
          border: 1px solid $accent-color;
        }
      }
    }
  }
}

@import "./base";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  width: 1%;
  height: 1%;
  background-color: #46484e9d;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 1.3s;

  &--active {
    display: flex;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: 1.3s;
  }

  &__card {
    width: 600px;
    height: 400px;
    display: flex;
    padding: 50px;
    line-height: 1.5em;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    background-color: white;
    border-radius: $small-radius;
    box-shadow: $big-shadow;

    &__header {
      padding: 20px;
      font-size: 1.2em;
      font-weight: 700;
    }

    &__content {
      padding: 20px;
    }

    &__error {
      font-weight: bold;
      color: red;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
    }
  }
}

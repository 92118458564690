@import "../../base";

.hourly-rates {
  width: calc(100vw - 390px);
  padding: 10px;
  display: flex;
  overflow: auto;
  max-height: calc(100vh - 420px);
  background-color: $foreground-color;
  margin: 20px;
  border-radius: $small-radius;
  box-shadow: $normal-shadow;
  &__row {
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(241, 241, 241);

    &:hover {
      background-color: rgb(249, 249, 249);
    }
  }
}

tbody {
  display: block;
  overflow: auto;
}

thead,
tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}

td {
  vertical-align: middle !important;
}

@import "../../base";

.redir-with-prods-page {
  height: $page-height;
  width: calc(100vw - 350px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 60px;
  margin-right: 60px;

  &__title {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 50px;
  }

  &__subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 450px);
  }
}

.selectable-products-list {
  width: calc(100vw - 450px);
  height: calc(100vh - 450px);
  padding: 10px;
  overflow: auto;
  background-color: $foreground-color;
  margin-top: 30px;
  margin-bottom: 0;
  border-radius: $small-radius;
  box-shadow: $normal-shadow;

  &__header {
    display: flex;
    width: 80%;
    justify-content: flex-start;
    font-weight: 600;
    padding-left: 10px;

    &__item {
      width: 30%;

      &--first {
        width: 40%;
      }
    }
  }

  &__filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include placeholder {
      font-style: italic;
      color: rgb(187, 185, 185);
    }

    &__label {
      font-weight: 600;
      padding-left: 10px;
    }

    &__input {
      max-width: 400px;
      width: 90%;
      min-width: 100px;
      margin-left: 20px;
      height: 39px;
      background-color: $foreground-color;
      border-radius: $big-radius;
      border: 0;
      box-shadow: $normal-shadow;
      font-family: $primary-font;
      caret-color: $accent-color;
      color: $accent-color;
      fill: $accent-color;
      padding: 10px 20px 10px 25px;

      &:disabled {
        background-color: $disabled-color;
        box-shadow: $disabled-shadow;
        fill: $disabled-color;
      }

      &:focus {
        border: 1px solid $accent-color;
      }
    }
  }
}

.switch {
  cursor: pointer;
  input {
    display: none;
    & + span {
      width: 48px;
      height: 28px;
      margin-right: 20px;
      border-radius: 14px;
      transition: all 0.3s ease;
      display: block;
      position: relative;
      background: $faded-color;
      box-shadow: 0 8px 16px -1px rgba($faded-color, 0.2);
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        transition: all 0.3s ease;
      }
      &:before {
        top: 5px;
        left: 5px;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        border: 5px solid #fff;
      }
      &:after {
        top: 5px;
        left: 32px;
        width: 6px;
        height: 18px;
        border-radius: 40%;
        transform-origin: 50% 50%;
        background: #fff;
        opacity: 0;
      }
      &:active {
        transform: scale(0.92);
      }
    }
    &:checked {
      & + span {
        background: $accent-color;
        box-shadow: 0 8px 16px -1px rgba($accent-color, 0.2);
        &:before {
          width: 0px;
          border-radius: 3px;
          margin-left: 27px;
          border-width: 3px;
          background: #fff;
        }
        &:after {
          animation: blobChecked 0.35s linear forwards 0.2s;
        }
      }
    }
    &:not(:checked) {
      & + span {
        &:before {
          animation: blob 0.85s linear forwards 0.2s;
        }
      }
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #6565881a;
    height: 50px;

    &__label {
      font-weight: 600;
      border-bottom: 0;
      margin-left: 8px;
    }
  }
}

@keyframes blob {
  0%,
  100% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.12, 0.94);
  }
  60% {
    transform: scale(0.96, 1.06);
  }
}

@keyframes blobChecked {
  0% {
    opacity: 1;
    transform: scaleX(1);
  }
  30% {
    transform: scaleX(1.44);
  }
  70% {
    transform: scaleX(1.18);
  }
  50%,
  99% {
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 0;
  }
}

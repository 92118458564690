@import "../base";

.main-page {
  height: $page-height;
  width: calc(100vw - 350px);
  display: flex;
  justify-content: center;
  align-items: center;

  &__title {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

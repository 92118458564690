@import "../base";

.instructions-page {
  height: $page-height;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 60px;
}

.alert {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60px;

  &__message {
    color: $danger-color;
    font-weight: 700;
  }
}

@import "../../base";

.line-picker {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  &__label {
    font-size: 1.3em;
    padding-left: 10px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    stroke: $primary-color;
    align-items: center;
  }

  &__select {
    width: 100%;
    height: 40px;
    background-color: $foreground-color;
    border-radius: $small-radius;
    border: 0;
    box-shadow: $normal-shadow;
    font-family: $primary-font;
    margin-top: 15px;
    color: $primary-color;
    padding: 10px;
    padding-right: 30px;
    font-size: 0.9em;
    cursor: pointer;

    &:disabled {
      background-color: $disabled-color;
      box-shadow: $disabled-shadow;
    }
  }
}

.chosen-line {
  width: 100%;
  display: flex;
  height: 75px;
  align-items: center;
  padding-top: 30px;
  justify-content: center;
  color: $accent-color;
  text-align: center;
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 30px;
}

.line-icon {
  width: 28px;
  fill: inherit;
  stroke: inherit;
  margin-right: 8px;
}

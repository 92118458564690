@import "../../base";

.eff-card-v3 {
  background-color: $foreground-color;
  margin-top: 30px;
  border-radius: $big-radius;
  width: 100%;
  min-height: 10vw;
  height: 40%;
  max-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.4vw;
  font-weight: 700;
  box-shadow: $normal-shadow;

  &__item {
    display: flex;
    width: 90%;
    height: 40%;
    padding: 1vw;
    flex-direction: row;
    margin: 0px;
    justify-content: space-around;
    align-items: center;


    &--accent {
      flex-direction: column;
      border-radius: $big-radius;
      background-color: $background-color;
    }

    &--thin {
      font-weight: 400;
    }

    &--red {
      color: $danger-color;
    }

    &--green {
      color: $success-color;
    }

    &--yellow {
      color: #ffae00;
    }
  }
}
